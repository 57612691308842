@import '../../marketplace.css';

@custom-media --vpMax767 (max-width: 767px);

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 0;
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.listingContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 30px;
}

.listingSidebar {
  flex-basis: 20%;
  flex-grow: 1;
  display: none;

  @media (--viewportMedium) {
    position: fixed;
    display: block;
  }
}

.sectionImages {
  flex-basis: 100%;
  flex-grow: 1;
  margin: 0 0 20px;

  @media (--viewportMedium) {
    flex-basis: 80%;
    margin-left: calc(20% + 20px);
  }

  @media (--viewportLarge) {
    flex-basis: 30%;
    margin: 0;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  /* background-color: var(--matterColorNegative); */ /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
  object-fit: contain;
  min-height: 322.6px;
  max-height: 322.6px;

  @media (--viewportSmall) {
    min-height: 497.59px;
    max-height: 497.59px;
  }

  @media (--viewportLarge) {
    min-height: 269px;
    max-height: 269px;
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.carouselNavigation {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  width: 100%;
}

.arrow,
.dot {
  display: block;
}

.arrow {
  display: block;
  height: 26px;
  line-height: 1;
  margin: 0 10px;
  width: 16px;
}

.dot {
  border-radius: 100%;
  border: 1px solid var(--marketplaceColor);
  height: 6px;
  margin: 0 5px;
  width: 6px;
}

.dotActive {
  background-color: var(--marketplaceColor);
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  min-height: inherit;
  max-height: inherit;
  transition: var(--transitionStyleButton);

  @media (--viewportSmall) {
  }

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: auto;

    &:hover {
      transform: scale(1.005);
    }
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  order: 3;
  @media (--vpMax767) {
    width: 100%;
  }

  @media (--viewportMedium) {
    display: flex;
    flex-basis: 80%;
    flex-grow: 1;
    margin: 0;
    margin-left: calc(20% + 20px);
    max-width: none;
    position: relative;
  }

  @media (--viewportLarge) {
    flex-basis: 45%;
    order: initial;
    padding: 0 50px 0 0;
  }

  @media (--viewportLarge) {
    /* max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px; */
  }

  @media (--viewportXLarge) {
    /* max-width: 1056px;
    padding: 0; */
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 0px;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  /* margin-top: 22px; */
  margin-top: 0;
  /* margin-bottom: 34px; */
  margin-bottom: 8px;
  display: flex;

  @media (--vpMax767) {
    flex-direction: column;
  }

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-bottom: 53px; */
    margin-bottom: 16px;
    margin-top: 0;
  }
}

.desktopPriceContainer {
  @media (--vpMax767) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
  }

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
  }

  & .break {
    width: 100%;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);
  font-size: 32px;
  line-height: 1.2;
  /* margin-bottom: 8px; */

  @media (--vpMax767) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
    font-size: 32px;
    font-weight: 500;
  }
}

.oldPriceValue {
  color: #aaaaaa;
  text-decoration: line-through;
  margin-right: 5px;
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--marketplaceColor);
  font-size: 20px;
  margin-top: 0;

  @media (--vpMax767) {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  /* margin-bottom: 24px; */
  padding: 0;

  @media (--vpMax767) {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  font-size: 32px;
  letter-spacing: normal;
  line-height: 1.2;

  /* Layout */
  width: 100%;
  margin: 0 0 6px;
}

.buyButton,
.saleButton,
.putOnSaleButton {
  display: inline-block;
  min-height: 36px;
  padding: 0 10px;
  width: auto;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    margin-bottom: 0px;
  }
}

.saleButton,
.putOnSaleButton {
  background-color: var(--matterColorAnti);
}

.saleButton:hover,
.putOnSaleButton:hover {
  background-color: var(--matterColorAnti);
}

.putOnSaleButton {
  margin-left: 10px;
}

.author {
  @apply --marketplaceH5FontStyles;
  color: var(--marketplaceColor);
  font-size: 20px;
  margin-bottom: 32px;
  margin-top: 0;

  & a {
    text-decoration: underline;
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.sectionDescription,
.sectionCondition {
  margin-bottom: 32px;
}

.descriptionTitle,
.conditionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorDark);
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;

  margin: 0 0 2px;
}

.sectionFeatures {
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.featuresItem,
.returns {
  color: var(--matterColorDark);
  font-size: 14px;
  line-height: 26px;
  margin: 0;
}

.featuresItem strong {
  font-weight: 500;
}

.returns {
  margin: 32px 0;
}

.description,
.condition {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColorDark);
  font-size: 14px;
  line-height: 26px;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  display: block;
  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    display: none;
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  margin-bottom: 5px;

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  margin-bottom: 5px;

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.hostInfo {
  color: var(--matterColorDark);
  font-size: 14px;
  line-height: 26px;
}

.hostInfo > span {
  font-weight: 500;
}

.hostInfo a {
  color: var(--matterColorDark);
  text-decoration: underline;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  /* max-width: 640px;
  max-height: 640px; */
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }
}

.sectionRelated {
  flex-basis: 100%;
  margin: 50px 0 0;
  order: 4;
  max-width: 100%;

  @media (--viewportMedium) {
    margin-left: calc(20% + 20px);
  }

  @media (--viewportLarge) {
    margin: 50px 0 50px 20px;
    padding-left: 20%;
  }
}

.listingCards {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    display: flex;
    flex-wrap: wrap;
    margin: 25px -10px 80px;
  }
}

.listingCard {
  margin-bottom: 20px;
  width: 50%;

  @media (--viewportMedium) {
    padding: 0 10px;
    width: 33.333%;
  }

  @media (--viewportLarge) {
    margin-bottom: 20px;
  }
}

.pinContainer {
  color: var(--matterColorDark);
  display: flex;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.footer {
  margin: 0;

  @media (--viewportLarge) {
    margin-left: calc(20% + 20px);
    width: auto;
  }
}

.bigScreen {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.smallScreen {
  display: flex;

  @media (--viewportMedium) {
    display: none;
  }
}

.collection {
  display: block;
}

.collectionOptionWrap {
  display: block;
  text-align: left;
}

.sectionHeadingWrap {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    margin-bottom: 53px;
    margin-top: 0;
  }
}

.collectionOption {
  @apply --marketplaceH5FontStyles;
  font-weight: 400;
  cursor: pointer;
  display: inline-block;

  & input {
    display: inline-block;
    width: auto;
    margin-right: 8px;
  }

  &:first-child {
    margin-right: 16px;
  }
}

.checkboxRoot {
  display: inline-block;
  position: relative;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.checkboxInput {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:checked + label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
    stroke: var(--successColor);
  }

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
    stroke: var(--marketplaceColor);
    stroke-width: 1px;
  }

  /* Display the "check" when checked */
  &:checked + label .checkedSuccess {
    display: inline;
    stroke: var(--successColor);
    stroke-width: 1px;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--matterColorDark);
  }
}

.checkboxLabel {
  display: flex;
  align-items: center;
  padding: 0;
}

.checkboxWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -1px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 20px;
    margin-right: 8px;
  }
}

.checked {
  display: none;
  fill: var(--marketplaceColor);
}
.checkedSuccess {
  display: none;
  fill: var(--successColor);
}

.boxSuccess,
.box {
  stroke: var(--matterColorAnti);

  @media (--viewportMedium) {
    stroke: var(--matterColorDark);
  }
}

.text {
}
.textRoot {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;

  @media (--viewportMedium) {
    color: var(--matterColorDark);
    font-size: 16px;
    line-height: 20px;
  }
}

.mapIconText {
  font-size: 17px;
  color: #000000;
  font-weight: var(--fontWeightBold);
  padding-right: 10px;
  margin: 0;
}

.mapIcon {
  height: 27px;
  & :global(.react-toggle) {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  & :global(.react-toggle-screenreader-only) {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  & :global(.react-toggle--disabled) {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  & :global(.react-toggle-track) {
    width: 43px;
    height: 21px;
    padding: 0;
    border-radius: 30px;
    background-color: #4d4d4d;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  & :global(.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track) {
    background-color: #000000;
  }

  & :global(.react-toggle--checked .react-toggle-track) {
    background-color: var(--marketplaceColor);
  }

  & :global(.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track) {
    background-color: var(--marketplaceColor);
  }

  & :global(.react-toggle-track-check) {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  & :global(.react-toggle--checked .react-toggle-track-check) {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  & :global(.react-toggle-track-x) {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  & :global(.react-toggle--checked .react-toggle-track-x) {
    opacity: 0;
  }

  & :global(.react-toggle-thumb) {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 19px;
    height: 19px;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    background-color: #fafafa;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  & :global(.react-toggle--checked .react-toggle-thumb) {
    left: 23px;
    border-color: var(--marketplaceColor);
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 10px;
}

.map > div {
  max-width: initial !important;
}

.mapContainer {
  @media (--viewportLarge) {
    padding: 0 0 0 0;
  }
}

.showMapMobile {
  display: inline;
  @media (--viewportMedium) {
    display: none;
  }
}

.listingCardContainer {
  display: contents;

  @media (--viewportMedium) {
    &:last-child {
      display: none;
    }
  }
}

.heart {
  width: 21px;
  height: 21px;
  margin-top: 3px;
  margin-right: 5px;

  @media (--viewportMedium) {
    width: 20px;
    height: 20px;
    margin-top: -5px;
    margin-right: 7px;
  }
}

.heartContainer {
  display: flex;
  right: 0;
  margin-top: 13px;
  width: 100%;

  @media (--viewportMedium) {
    position: absolute;
    right: 0;
    width: auto;
    display: initial;
    margin-top: 0;
  }

  @media (--viewportLarge) {
    right: 49px;
  }
}

.positionInitial {
  position: initial;
}

.dark {
  color: #000000;
}
