@import '../../marketplace.css';

.root {
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: var(--marketplaceColor);

  width: auto;
  padding: 24px 0 42px 0;
  margin: 0 0;
  background-color: var(--matterColorLight);

  @media (--viewportSmall) {
    margin: 0 24px;
  }

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarger) {
    padding: 0 0 81px 0;
    max-width: var(--ContainerWidthLarge);
    margin: 0 auto;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 44px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    margin: 0;
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  align-items: flex-start;
  display: block;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;
  position: relative;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    display: flex;
    margin-bottom: 0;
    flex-direction: row;
  }

  @media (--viewportLarge) {
    justify-content: flex-start;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 40%;

  @media (--viewportMedium) {
    position: relative;
    float: none;
    flex-shrink: 0;
    flex-basis: 130px;
    margin-right: 65px;
    margin-right: 24px;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    display: block;
    order: 1;
    margin-right: 65px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: right center;
  width: 100%;
  height: 130px;

  @media (--viewportMedium) {
    object-position: left center;
  }
}

.organizationInfo {
}

.organizationDescription {
  @apply --marketplaceTinyFontStyles;
}

.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
  margin-top: 32px;
}

.copyrightLink {
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex-basis: auto;
  margin-right: 0;
  margin-bottom: 0px;
  min-width: 50px;
  width: 100%;
  align-self: flex-start;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 42px;
    margin-bottom: 24px;
    width: auto;
    margin-bottom: 24px;
  }
}

.firstInfoLinks > ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    flex-direction: column;
  }
}

.firstInfoLinks li {
  padding-right: 20px;

  @media (--viewportMedium) {
    padding-right: 0;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: var(--matterColorDark);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.firstInfoLinks .link {
  font-weight: 600;

  @media (--viewportMedium) {
    font-weight: 400;
  }
}

.link svg {
  margin-left: 8px;
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: auto;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 42px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: auto;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 42px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: auto;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 42px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}

.infoLinks:nth-child(1) .list {
  display: flex;

  @media (--viewportMedium) {
    display: block;
  }
}

.infoLinks:nth-child(1) .list .listItem {
  margin-right: 15px;
  margin-bottom: 25px;

  @media (--viewportMedium) {
    margin-right: 0px;
    margin-bottom: 0px;
  }
}

.infoLinks:nth-child(1) .list .listItem .link {
  @apply --marketplaceH4FontStyles;
  font-size: 17px;
  font-weight: 500;

  @media (--viewportMedium) {
    margin-right: 0px;
    font-size: 14px;
    font-weight: 400;
  }
}
