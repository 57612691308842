@import '../../marketplace.css';

.heart {
  width: 25px;
  height: 25px;
  margin-top: 0;
  color: var(--marketplaceColor);
  cursor: pointer;

  @media (--viewportMedium) {
    margin-top: -10px;
  }
}
