@import '../../marketplace.css';

.root {
  /* Layout */
  max-width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    max-width: calc(33.33% - 20px);
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 100%; /* 1:1 Aspect Ratio */
  position: relative;
  overflow: hidden;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* Fill square and center image */
  height: 100%;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 8px 0 2px 0;

  @media (--viewportMedium) {
    padding: 16px 0 2px 0;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-size: 13px;
  line-height: 1;
  text-align: right;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 4px;

  @media (--viewportMedium) {
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 18px;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 4px;

  @media (--viewportMedium) {
    margin-bottom: 8px;
  }
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorDark);
  font-size: 14px;
  line-height: 1;

  margin-bottom: 0;
  margin-top: 0;
  margin-right: 16px;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.locationInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorDark);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-top: 0;
  overflow: hidden;
  margin-right: 16px;
}

.newItem {
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  white-space: nowrap;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.mapMarkerIcon {
  transform: scale(0.8);
  max-width: 20px;
  min-width: 20px;

  @media (--viewportMedium) {
    transform: scale(1);
  }
}

.address {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sold,
.sale {
  font-weight: 500;
  font-size: 9px;
  letter-spacing: 1.5px;
  color: #ffffff;
  background: #56b374;
  padding: 0px 5px;

  @media (--viewportMedium) {
    font-size: 10px;
    padding: 1px 10px;
    line-height: 24px;
  }
}
.sale {
  margin-right: 5px;
  background: #ed7273;
}
.oldPriceValue {
  color: #aaaaaa;
  text-decoration: line-through;
  margin-right: 5px;
}
