@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  /* padding: 0 0 96px 0; */
  padding: 0 0 32px 0;
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-between; */
  }

  @media (--viewportLarge) {
    margin-right: -20px;
    padding: 0 0 96px 36px;
  }

  @media (--viewportLarger) {
    margin-right: 0;
  }
}

.listingCard {
  margin-bottom: 20px;
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: calc(50% - 10px);
  }

  @media (--viewportLarge) {
    flex-basis: calc(33.333% - 20px);
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  margin-bottom: 20px;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.searchFooter {
  @media (--viewportLarge) {
    padding-left: 36px;
  }
}

.searchFooter .searchFooterInner {
  margin: 0;

  @media (--viewportMedium) {
    padding-left: 0;
    padding-right: 0;
  }
}

.loadMoreButtonWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.loadMoreButton {
  display: inline-block;
  min-height: 36px;
  padding: 0 10px;
  width: auto;
}

.soldAlertMainContainer {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;

  @media (--viewportMedium) {
    width: 46%;
    padding-left: 10px;
    padding-right: 0;
  }

  @media (--viewportLarge) {
    width: 50%;
    padding-left: 45px;
  }
}

.soldContainer {
  background: var(--marketplaceColor);
  padding: 10px 19px;
  font-size: 18px;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 500;

  @media (--viewportMedium) {
    font-size: 15px;
  }
}

.soldContainer > a {
  color: #ffffff;
  text-decoration: underline;
}

.listingContainer {
  display: contents;
}

.disabledSold {
  pointer-events: none;
  cursor: default;
}
