@import '../../marketplace.css';

.root {
  background-color: var(--matterColorLight);
}
.content {
  width: calc(100% - 48px);
  margin: 12px 24px 24px 24px;

  @media (--viewportMedium) {
    max-width: 565px;
    margin: 55px auto 56px auto;
  }
}

.contentModal {
  @media (--viewportMedium) {
    max-width: 565px;
    margin: 55px auto 56px auto;
  }
}

.headingContainer {
  display: flex;
  flex-direction: row;
}

.heading {
  margin-right: 24px;
  hyphens: auto;
  font-size: 15px;
  @media (--viewportSmall) {
    font-size: 30px;
  }
}

.profileLink {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;

  display: inline-block;
  width: auto;
  min-height: auto;
  height: 41px;

  float: right;
  flex-shrink: 0;

  margin: 19px 0 0 auto;
  padding: 12px 16px 5px 16px;

  @media (--viewportMedium) {
    margin: 37px 0 0 auto;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  display: flex;
  justify-content: flex-end;
  color: #000000;
  cursor: pointer;
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  @apply --marketplaceModalErrorStyles;
}

.typeDescription {
  @apply --marketplaceBodyFontStyles;
  color: var(--marketplaceColor);
}

.productTypeModalTitle {
  @apply --marketplaceModalTitleStyles;
  margin-bottom: 32px;
}

.productTypeModalButton {
  margin-bottom: 16px;
}
