@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;

  margin: 30px 24px 0;
  margin-top: 0;

  @media (--viewportLarger) {
    margin: 30px auto 0;
    max-width: var(--ContainerWidthLarge);
    width: 100%;
  }
}

.scrollingDisabled {
  /* position: fixed and width are added to prevent iOS from scrolling content */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
