@import '../../marketplace.css';

.container {
  /* padding-left: 180px; */
  width: auto;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;

  @media (--viewportLarge) {
    padding-left: 180px !important;
    width: 600px !important;
    padding: 40px;
  }

  @media (--viewportMedium) {
    padding-left: 100px;
    width: 550px;
  }
}

.container p {
  font-size: 15px;
  margin: 0px;
  line-height: 22px;
  margin-bottom: 10px;
}

.title {
  color: var(--marketplaceColor);
  font-size: 24px;
  margin: 0px;
}
