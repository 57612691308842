@import '../../marketplace.css';

.root {
  padding-top: 0;
  padding-bottom: 16px;

  @media (--viewportMedium) {
    /* padding-top: 24px; */
  }
}

.filterLabel,
.filterLabelSelected {
  @apply --marketplaceH3FontStyles;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
}

.filterLabel {
  color: var(--matterColorDark);
}

.filterLabelSelected {
  color: var(--marketplaceColor);
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.optionsContainerOpen {
  height: auto;
  padding-bottom: 16px;
}

.optionsContainerClosed {
  height: 0;
  overflow: hidden;
}

.hasBullets {
  padding-left: 26px;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.optionBullet,
.optionBulletSelected {
  position: absolute;
  left: -5px;
  top: 13px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: var(--marketplaceColor);
  transition: opacity var(--transitionStyleButton);
}

/* left animated "border" like hover element */
.optionBullet {
  opacity: 0;
}

/* left static border for selected element */
.optionBulletSelected {
  opacity: 1;
}

.optionWrap {
}

.option {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorDark);
  /* font-size: 22px; */
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  height: 32px;

  /* Layout */
  display: block;
  position: relative;
  margin: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transitionStyleButton);

  padding: 0;

  @media (--viewportMedium) {
    height: 40px;
  }
}

.optionSelected {
  composes: option;

  & .optionText {
    color: var(--matterColorDark);

    &:hover {
      color: var(--matterColorDark);
    }

    border-bottom: 2px solid var(--marketplaceColor);

    @media (--viewportMedium) {
      border-bottom-width: 4px;
    }
  }
}

.clearButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.amenitiesFilterWrapper {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: transform 0.25s ease-in-out, max-height 0.1s linear, opacity 0.5s ease-in-out;
  transform: scaleY(0);
  transform-origin: top;

  @media (--viewportMedium) {
    display: block;
  }
}

.amenitiesFilterWrapperOpen {
  transform: scaleY(1);
  max-height: 2700px;
  opacity: 1;
}

.amenitiesFilterWrapper ul {
  /* margin-top: 5px; */
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 8px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  /* @media (--viewportMedium) {
    border-top: solid 2px var(--marketplaceColor);
  } */
}

/* .amenitiesFilterWrapper ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 35%;
  height: 3px;
  background-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    display: none;
  }
} */

.optionsContainer {
  position: relative;
}

.closeFilterButton {
  @apply --marketplaceModalCloseStyles;
  position: absolute;
  padding: 12px 0;
  padding-right: 6px;
  align-items: center;
  font-size: 12px;
  z-index: 1;
  top: -1px;

  & span {
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
  }

  @media (--viewportMedium) {
    display: none;
  }
}

.resultText {
  @apply --marketplaceModalCloseStyles;
  font-family: 'Gordita', 'sofiapro', Helvetica, sans-serif;
  display: inline-block;
  position: absolute;
  padding: 0;
  align-items: center;
  font-size: 12px;
  z-index: 1;
  top: 37px;
  text-transform: uppercase;
  color: var(--marketplaceColor);

  & span {
    display: inline-block;
    margin-right: 2px;
    line-height: 1;
  }

  @media (--viewportMedium) {
    display: none;
  }
}

.arrowIcon {
  display: inline-block;
  transform: rotate(90deg) scale(0.7);
}

.optionText {
  display: inline-block;

  border-bottom: 2px solid transparent;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    font-size: 22px;
    border-bottom-width: 4px;

    &:hover {
      color: var(--marketplaceColor);
      text-decoration: none;
    }
  }
}

.divider {
  background: #000000;
  height: 4px;
  width: 18px;
  margin: 16px 0;
}

.itemText {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
  font-size: 13px;
  line-height: 24px;

  @media (--viewportMedium) {
    color: var(--matterColorDark);
    font-size: 16px;
    line-height: 20px;
  }
}
