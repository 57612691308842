@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 55px;
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: var(--LandingPage_sectionMarginTopLarge);
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 0;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding: 0;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

.sectionContent {
  /* margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px; */
  margin: 0 0 0 0;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarger) {
    max-width: var(--ContainerWidthLarge);
    padding: 0;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 55px auto;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

.section:last-child .sectionContent {
  @media (--viewportMedium) {
    margin-bottom: 110px;
  }
}

.recentlyListed {
}

.pageContent {
  margin: 30px 0 0;
  margin-top: 0;

  @media (--viewportMedium) {
    margin: 0 auto;
    margin-top: 0;
  }
}

.mobileView,
.mobileViewHeader {
  padding-left: 17px;
  padding-right: 17px;

  @media (--viewportMedium) {
    padding-left: 0;
    padding-right: 0;
  }
}

.mobileViewHeader {
  background-color: white;
}
