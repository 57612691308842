@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Compose text label items on top of this */
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  --TopbarDesktop_label: {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    text-decoration: inherit;
  }

  --TopbarDesktop_linkHover: {
    transition: var(--transitionStyleButton);

    &:hover {
      text-decoration: none;
    }
  }
}

/* Desktop */
.root {
  /* Size */
  width: 571px;
  height: auto;
  position: relative;

  /* Layout for child components */
  /* display: flex; */
  flex-direction: column;
  margin: 26px 0 20px auto;
  padding: 0 0 0 20px;
  padding: 10px 0 10px 20px;
  flex-wrap: wrap;

  /* fill */
  background-color: var(--matterColorLight);
}

.navWrapperContainer {
  margin-left: auto;
  width: 455px;
}

.navWrapperTop {
  margin-bottom: 7px;
}

.userNav {
  max-width: 455px;
  margin-left: auto;
}

.navWrapperLeft {
  max-width: 205px;
  width: 100%;
  margin-left: auto;
}

.navWrapperRight {
  max-width: 200px;
  width: 100%;
  margin-left: 50px;
}

.navButtonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
}

.navButtonContainerAuth {
  justify-content: flex-end;
  align-items: center;
}

.inboxContainer {
  margin-right: 43px;
}

.navWrapper {
  height: auto;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fixedMenu {
  position: fixed;
  min-width: 571px;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px 15px 10px 10px;
  margin-top: -10px;
  margin-left: -5px;
}

/* logo */
.logoLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  padding: 21px 24px 21px 24px;
  height: 100%;

  @media (--viewportLarge) {
    left: 0;
    padding: 0;
    position: absolute;
    top: 50px;
  }
}

.logo {
  height: 157px;
  width: 157px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin: 0;
  width: 100%;

  @media (--viewportMedium) {
    padding-left: 0;
    max-width: 240px;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  align-items: flex-end;
  border-bottom: 2px solid var(--marketplaceColor);
  display: flex;
  height: 46px;
  margin-left: 32px;
  margin-top: 0;
  padding-left: 0;

  @media (--viewportMedium) {
    margin-left: 0;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH4FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListing {
  @apply --TopbarDesktop_label;
}

/* Inbox */
.inboxLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH4FontStyles;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 3px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 0 0 12px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  border-radius: 2px;
  flex-shrink: 0;
  height: auto;
  max-height: 36px;
  padding: 3px 12px 8px;

  color: var(--matterColorLight);
  background-color: var(--matterColorDark);

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  border-radius: 2px;
  flex-shrink: 0;
  height: auto;
  max-height: 36px;
  padding: 3px 12px 8px;
  margin-left: 40px;

  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.basicLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 500;
  height: auto;
  padding: 0;

  color: var(--matterColorDark);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.basicLink:first-of-type {
  margin-left: 0;
}

.signup,
.login {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
}

.basicLinkInner {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH4FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.linkActive {
  color: var(--marketplaceColor);
}
