@import '../../marketplace.css';

.field label {
  font-size: 13px;
}

.field {
  margin-bottom: 5px;
  overflow: hidden;
}

.field input,
.field textarea {
  background-color: #e9e7e8;
  border-bottom-width: 0px;
  padding-left: 5px;
  font-size: 15px;
  height: 37px;
}

.submitButton {
  width: 69px;
  height: 33px;
  font-size: 16px;
  min-height: auto;
}

.field textarea {
  min-height: 150px;
  max-height: 150px;
}

.submitContainer {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submitContainer p {
  margin-bottom: 0px !important;
  color: var(--successColor);
  font-size: 18px !important;
  font-weight: 500;
}
