@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.root {
  /* @apply --backgroundImage; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);

  @apply --SectionHero_animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  @apply --SectionHero_animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  @apply --SectionHero_animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.heroVideo {
  margin: 0 auto;
  max-width: 330px;
  margin-bottom: 15px;
  margin-top: -33px;

  @media (--viewportMedium) {
    margin: -30px auto 0;
    max-width: 100%;
    width: 600px;
    height: 600px;
  }
}

.subTitle {
  color: var(--marketplaceColor);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.3;
  margin: -40px auto 60px;
  max-width: 300px;
  text-align: center;
  z-index: 1;

  @media (--viewportMedium) {
    display: none;
  }
}

@custom-media --vp284 (min-width: 284px);
@custom-media --vp300 (min-width: 300px);
@custom-media --vp316 (min-width: 316px);
@custom-media --vp332 (min-width: 332px);
@custom-media --vp348 (min-width: 348px);
@custom-media --vp364 (min-width: 364px);
@custom-media --vp380 (min-width: 380px);
@custom-media --vp396 (min-width: 396px);

.heroDescription {
  color: var(--matterColorDark);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  margin: -50px auto 0;
  max-width: 90%;
  text-align: center;
  z-index: 1;

  & span {
    line-height: 1.7;
    display: inline;

    @media (--viewportMedium) {
      line-height: 1.8;
      display: block;
    }
  }

  @media (--vp284) {
    font-size: 15px;
  }

  @media (--vp300) {
    font-size: 16px;
  }

  @media (--vp316) {
    font-size: 17px;
  }

  @media (--viewportMedium) {
    font-size: 22px;
    line-height: 1.6;
    margin-bottom: 60px;
    max-width: 720px;
  }
}

.link {
  text-decoration: underline;

  @media (--viewportMedium) {
    text-decoration: none;
  }
}
