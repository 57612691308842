@import '../../marketplace.css';

.pageTitle {
  text-align: center;
  color: var(--marketplaceColor);
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.contentMain ul {
  padding-left: 30px;
  list-style: circle;
}

.contentMain li {
  @apply --marketplaceBodyFontStyles;
}

.contentMain img {
  max-width: 100%;
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 48px;
  margin-bottom: 24px;
}
